/**
* Send logs to ClickHouse. logs can be a single log, but usually it's an array of logs
*/
export async function sendLogsToIngestEndpoint(logs: any): Promise<[string, number]> {
  // IMPORTANT! Don't log anything here!
  const dogfoodApiKey = process.env.IDLOG_DOGFOOD_API_KEY;
  if (!dogfoodApiKey) {
    throw 'dogfoodApiKey is not set';
  }

  // send HTTP request to /v1/ingest with the same body as req.body
  const baseUrl = process.env.IDLOG_API_BASEURL;
  if (!baseUrl) {
    throw 'IDLOG_API_BASEURL is not set';
  }
  const fullUrl = (baseUrl + '/api/v1/ingest').replace('//api', '/api');
  console.log('sendLogsToClickHouse: fullUrl:', fullUrl);
  const response = await fetch(fullUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': dogfoodApiKey
    },
    body: JSON.stringify(logs)
  });


  const responseText = await response.text();
  const responseStatus = response.status;
  return [responseText, responseStatus]
}